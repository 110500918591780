import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { CookiesProvider } from 'react-cookie';
import { AnswerProvider } from './contexts/AnswerContext';
import { PermissionProvider } from './contexts/PermissionContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AnswerProvider>
      <CookiesProvider>
        <AuthProvider>
          <PermissionProvider>
            <App />
          </PermissionProvider>
        </AuthProvider>
      </CookiesProvider>
    </AnswerProvider>
  </React.StrictMode>
);
