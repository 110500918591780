import './Social.css'

function Social() {
    return (
        <div className='Social' id='social'>
            <div className="social-participation">
                <div className='social--text'>
                    <h2>Participação Social</h2>
                    <p> O Estatuto da Cidade estabelece instrumentos básicos para garantir
                        a elaboração do Plano Diretor em conjunto com a população, através
                        da participação dos diversos segmentos e representantes da sociedade civil.
                        Devem ser feitas audiências públicas e divulgadas todas as informações
                        e conteúdos elaborados, de modo transparente e acessível à toda população.
                        A participação social é fundamental, pois dessa forma a comunidade tem a
                        oportunidade de contribuir para a construção de cidades mais justas e
                        sustentáveis.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Social;