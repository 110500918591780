import React from 'react';
import '../Global.css';
import Navbar from '../components/Navbar/Navbar';
import Contato from '../components/Contato/Contato';
import Footer from '../components/Footer/Footer';


function Contact() {

    return (
        <>
            <Navbar />
            <Contato />
            <Footer />
        </>
    );
}

export default Contact;