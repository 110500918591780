import "./NewsContent.css";

import seminario_cidade_banner from "../../assets/images/plan_diretor_banner_seminario.png";
import audiencia_cidade_banner from "../../assets/images/Capa Site Audiencia- Pág. NOTÍCIAS.png";
import primeira_rodada_audiencia from "../../assets/images/Capa Site - Pág. NOTÍCIAS - 1ª RODADA AUDIÊNCIAS.png";
import fotos_primeira_rodada_audiencia from "../../assets/images/Fotos site noticia_ 1ª Rodada.png";
import { Link } from "react-router-dom";

function NewsContent() {
    // const adicionaZero = (numero) => {
    //     if (numero <= 9)
    //         return "0" + numero;
    //     else
    //         return numero;
    // }

    return (
        <div className='NewsContent'>
            <div className='center'>
                <div className='news-title'>
                    <h1>NOTÍCIAS</h1>
                    <h3>
                        Fique por dentro do que acontece em todas as fases do Plano Diretor
                        do Município de Itajubá.
                    </h3>
                </div>

                <div className='news-items'>
                    {json
                        .slice()
                        .reverse()
                        .map((item, index) => (
                            <div key={index} className='news-cards'>
                                <div className='news-card news-step-3'>
                                    <img src={item.img} alt={item.title} />
                                    <h4>{item.title}</h4>

                                    {item.content}

                                    {/* <p dangerouslySetInnerHTML={{ __html: item.content }}></p> */}

                                    {/* <h3>{item.date}</h3> */}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default NewsContent;

const json = [
    {
        id: 1,
        title: "Está começando a revisão do Plano Diretor de Itajubá!\n",
        content: (
            <p style={{ textAlign: "justify" }}>
                <br />
                Participe do evento de abertura, o Seminário da Cidade, que vai
                acontecer no dia 9 de abril, às 19h no Teatro Municipal. Neste evento
                serão apresentadas todas as etapas da revisão do plano e sua importância
                para o desenvolvimento da cidade de Itajubá.
                <br />
                <br />
                O Plano Diretor, definido pelo Estatuto da Cidade (Lei Federal nº
                10.257/2001) é o instrumento básico da Política Urbana municipal e
                obrigatório para municípios com mais de 20 mil habitantes. Seu objetivo
                é ordenar o desenvolvimento urbano sustentável e garantir o pleno
                exercício das funções sociais da cidade e da propriedade urbana,
                buscando a promoção de espaços saudáveis e o direito à cidade, de
                maneira democrática e participativa.
                <br />
                <br />
                Acompanhe pelas redes sociais da prefeitura e pelo site oficial do
                plano:{" "}
                <a
                    href='http://pdmi.dacengenharia.com.br'
                    rel='noreferrer'
                    target='_blank'
                >
                    pdmi.dacengenharia.com.br
                </a>
                . Participe!
            </p>
        ),
        img: `${seminario_cidade_banner}`,
        date: "26/03/2024",
    },
    {
        id: 2,
        title:
            "Participe das audiências públicas da revisão do Plano Diretor de Itajubá!\n",
        content: (
            <p style={{ textAlign: "justify" }}>
                <br />
                Participe e colabore com as políticas públicas a serem aplicadas no
                município.
                <br />
                <br />
                Atualmente, Itajubá está fazendo a revisão de seu Plano Diretor,
                conforme a lei federal 10.257/2001 - Estatuto da Cidade. Essa lei
                determina que o processo de revisão do plano diretor conte com ampla
                participação popular e por isso é muito importante que você cidadão nos
                conte sua opinião!
                <br />
                <br />
                As audiências públicas vão acontecer em 16 pontos de apoio, oito em área
                urbana e oito em área rural, com o objetivo de apresentar os estudos e
                ouvir as demandas da população.
                <br />
                <br />
                Confira as datas e locais das audiências:
                <br />
                <br />
                ➡️ 25/junho (19h): Igreja São Tiago - Capituba <br />
                ➡️ 25/junho (19h): E. M. São Sebastião - Varginha <br />
                ➡️ 26/junho (19h): E. M. Ana Junqueira Ferraz - Ano Bom <br />
                ➡️ 26/junho (19h): E. E. Florival Xavier - Rebourgeon <br />
                ➡️ 27/junho (19h): E. M. Francisco Florêncio da Silva - Juru <br />
                ➡️ 27/junho (19h): E. M. Professor Rafael Magalhães - Boa Vista <br />
                ➡️ 28/junho (19h): E. E. Major João Pereira - São Vicente <br />
                ➡️ 01/julho (19h): APAE Rural - Pedra Preta <br />
                ➡️ 01/julho (19h): Associação do Bairro São Pedro - São Pedro <br />
                ➡️ 02/julho (19h): E. M. Olímpio José Joaquim - Capetinga <br />
                ➡️ 02/julho (19h): Auditório Principal Teatro Municipal - BPS <br />
                ➡️ 03/julho (19h): Escola Municipal Doutor Antônio Salomon - Cruzeiro
                <br />
                ➡️ 03/julho (19h): E. M. Alcides Faria - Rio Manso <br />
                ➡️ 04/julho (19h): Ponto de Atendimento José Gil de Souza - Estância
                <br />
                ➡️ 04/julho (19h): Associação do Bairro Santa Rosa - Santa Rosa <br />
                ➡️ 05/julho (19h): E. M. Profª. Isaura P. dos Santos - Açude <br />
                <br />
                Acesse nosso site e participe desse importante projeto para o
                desenvolvimento sustentável e democrático de Itajubá!
                <br />
                <br />
                Para saber mais sobre a revisão do Plano Diretor, visite o site:{" "}
                <a
                    href='http://pdmi.dacengenharia.com.br'
                    rel='noreferrer'
                    target='_blank'
                >
                    pdmi.dacengenharia.com.br
                </a>{" "}
                ou entre em contato pelo telefone (35) 2143-9087 e e-mail
                planejamento@daceng.com.br,
            </p>
        ),
        img: `${audiencia_cidade_banner}`,
        date: "26/03/2024",
    },
    {
        id: 3,
        title:
            "Acompanhe o que aconteceu na primeira rodada de audiências públicas da revisão do Plano Diretor de Itajubá!\n",
        content: (
            <p style={{ textAlign: "justify" }}>
                <br />
                A primeira rodada de Audiências Públicas da revisão do Plano Diretor de
                Itajubá aconteceu na última semana de junho e primeira semana de julho
                (dias 25 de junho à 5 de julho) em 16 locais distribuídos pelo
                município, oito na área urbana e oito na área rural.
                <br />
                <br />
                A equipe da DAC Engenharia fez a apresentação do diagnóstico técnico e
                participativo. O diagnóstico técnico trouxe as informações coletadas
                pela equipe para descrever a atual situação do município de Itajubá, com
                dados da população, do território e da infraestrutura e serviços
                disponíveis na cidade, bem como análises do atual plano diretor, seu
                zoneamento, parâmetros e as demandas de revisão.
                <br />
                <br />O plano aborda diversos temas, como equipamentos públicos, uso e
                ocupação do solo, habitação, expansão urbana, dinâmica imobiliária,
                mobilidade urbana, áreas de risco, saneamento, meio ambiente,
                desenvolvimento rural sustentável, entre outros. Os dados podem ser
                conferidos na íntegra na aba{" "}
                <Link to='/documentos' target='_blank' rel='noreferrer'>
                    Documentos
                </Link>{" "}
                do site do plano. Destaca-se a necessidade de novas áreas para
                empreendimentos de interesse social, o cálculo da expansão urbana que
                revela que o município já possui espaço para crescer nos próximos 20
                anos, sem necessidade de aumentar seu perímetro urbano; a necessidade de
                rever a metodologia do zoneamento; e a definição de parâmetros para as
                áreas.
                <br />
                <br />
                Além disso, também foram apresentados os resultados obtidos no
                diagnóstico participativo, com a análise feita a partir das respostas
                aos mais de quatro mil questionários respondidos pela população. A
                principal demanda no município é por melhorias na mobilidade urbana.
                <br />
                <br />
                Durante as apresentações a sociedade civil pôde contribuir falando sobre
                os problemas e potencialidades existentes em sua região e tirar dúvidas
                sobre o Plano.
                <br />
                <br />
                Para ler todo o conteúdo elaborado até o momento na revisão do Plano
                Diretor (Produtos 1, 2, 3.1 e 3.2), acesse a aba{" "}
                <Link to='/documentos' target='_blank' rel='noreferrer'>
                    Documentos
                </Link>{" "}
                do site!
                <br />
                <br />
                A apresentação da primeira rodada de audiências está disponível para
                download, contando com o diagnóstico técnico, que teve o mesmo conteúdo
                apresentado em todas as audiências, e contando com todo o conteúdo do
                diagnóstico participativo, não apenas dos bairros de cada setor,
                conforme foram apresentados durante as audiências.
                <br />
                <br />
                Continue acompanhando as próximas etapas da revisão do Plano Diretor
                pelo site oficial do Plano e pelo Instagram da DAC Engenharia (
                <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.instagram.com/dac_engenharia_ltda/'
                >
                    @dac_engenharia_ltda
                </a>
                ) onde serão divulgados os novos Produtos elaborados e próximos
                encontros.
                <br />
                <br />
                Confira algumas fotos das audiências abaixo.
                <br />
                <br />
                <img
                    src={fotos_primeira_rodada_audiencia}
                    alt='Registro das audiências que aconteceram em Itajubá entre junho/julho 2024'
                />
                <br />
                <h4>
                    Registro das audiências que aconteceram em Itajubá entre junho/julho
                    2024
                </h4>
                <br />
                Para saber mais sobre a revisão do Plano Diretor, visite o site:{" "}
                <a
                    href='http://pdmi.dacengenharia.com.br'
                    rel='noreferrer'
                    target='_blank'
                >
                    pdmi.dacengenharia.com.br
                </a>{" "}
                ou entre em contato pelo telefone (35) 2143-9087 e e-mail
                planejamento@daceng.com.br
                <br />
                <br />
                <a
                    href='https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/1%C2%AA+Rodada+Audi%C3%AAncias+-+Apresenta%C3%A7%C3%A3o+Completa.pdf'
                    target='_blank'
                    rel='noreferrer'
                >
                    Confira o conteúdo apresentado
                </a>
            </p>
        ),
        img: `${primeira_rodada_audiencia}`,
        date: "08/07/2024",
    },
];
