import React from "react";
// import { useNavigate } from 'react-router-dom';
import "./PollList.css";

function PollList({ polls }) {
  // const navigate = useNavigate();

  // const handleRedirect = (e) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     if (polls[e.target.id].questionaryLink !== '') {
  //         window.location.replace(polls[e.target.id].questionaryLink);
  //     } else {
  //         navigate(`/pesquisa`, { state: { data: polls[e.target.id] } })
  //     }
  // }

  return (
    <div className='PollList'>
      <div className='center'>
        <div className='info-card'>
          <strong>
            <h2 className='info-title'>
              O período de pesquisas do Plano Diretor já encerrou!
            </h2>
          </strong>
          <div className='center'>
            Confira os resultados no diagnóstico participativo na aba documentos
          </div>
          <div className='button-info center'>
            {/* <h2  > */}
            {/* </h2> */}
            {/* <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(
                  "https://forms.gle/EwUvmPn7P2tUG2kM8",
                  "_blank",
                  "noreferrer"
                );
              }}
            >
              Iniciar pesquisa!
            </button> */}
          </div>
        </div>

        {/* Não está recebendo respostas! 
                {(() => {
                    if (polls) {
                        return polls.map((questionary, index) => {
                            if (concluded.includes(questionary.questionaryId)) {
                                return (
                                    <div key={index} className="cardPesquisa">
                                        <div className="cardList">
                                            <div className="card-title">
                                                <h2>{questionary.questionaryTitle}</h2>
                                                <p className="blue">Tempo de duração: {questionary.questionaryDuration} minutos</p>
                                            </div>
                                            <div className="button-comecar">
                                                <button id={index} onClick={(event) => alert("Essa pesquisa não está mais recebendo respostas")}>Saiba mais!</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index} className="cardPesquisa">
                                        <div className="cardList">
                                            <div className="card-title">
                                                <h2>{questionary.questionaryTitle}</h2>
                                                <p className="blue">Tempo de duração: {questionary.questionaryDuration} minutos</p>
                                            </div>
                                            <div className="button-comecar">
                                                <button id={index} onClick={(event) => handleRedirect(event)}>Saiba mais!</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                })()} */}
      </div>
    </div>
  );
}

export default PollList;
