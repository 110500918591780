import React from 'react';
import '../Global.css';
import Navbar from '../components/Navbar/Navbar';
import AboutUs from '../components/Home/AboutUs/AboutUs';
import Footer from '../components/Footer/Footer';


function About() {

  return (
    <div style={{
      backgroundImage: `url(${require('../assets/images/Fundo.png')})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover', height: '100vh'
    }}>
      <Navbar />
      <div style={{ paddingTop: '10vh' }}><AboutUs /></div>
      <div style={{ bottom: 0, width: '100%', position: 'absolute' }}>
        <Footer />
      </div>
    </div >
  );
}

export default About;