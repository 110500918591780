import './Plane.css'

function Plane() {
    return (
        <div className='Plane'>
            <div className='plane-about'>
                <div className='plane--text'>
                    <h2>Sobre o plano diretor do município de Itajubá</h2>
                    <p> As cidades são espaços que concentram um grande número de pessoas,
                        serviços e atividades. Para garantir o acesso aos bens, serviços e
                        território urbano de maneira justa e priorizando a qualidade de vida,
                        saúde das pessoas e o bem coletivo, é necessário muito planejamento e
                        uma boa gestão do solo e equipamentos urbanos.
                    </p>
                    <p> O Plano Diretor, definido pelo Estatuto da Cidade (Lei Federal nº 10.257/2001),
                        é o instrumento básico da Política Urbana municipal e, portanto, obrigatório
                        para municípios com mais de 20 mil habitantes. Seu objetivo é ordenar o
                        desenvolvimento urbano sustentável e garantir o pleno exercício das funções
                        sociais da cidade e da propriedade urbana, buscando a promoção de espaços
                        saudáveis e o direito à cidade, de maneira democrática e participativa.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Plane;