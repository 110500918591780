import "./Steps.css";
import steps from "../../../assets/images/steps_etapa3.png";

function Steps() {
  return (
    <div className='Steps'>
      <h2>Etapas e produtos do Plano Diretor</h2>
      <div className='steps-items' />
      <div className='steps-image-container'>
        <img className='steps-image' src={steps} alt='Etapas e produtos' />
      </div>
    </div>
  );
}

export default Steps;
