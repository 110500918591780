import "./Banner.css";
import plano_mobilidade_banner from "../../../assets/images/plano-diretor-itajuba-logo-laranja@3x.png";
import seminario from "../../../assets/images/Capa Site - Pág. INICIAL - 1ª Rodada de Audiências-resultado.png";

// import { Link } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const slides = [
  {
    title: "plano-mobilidade",
    // control:
    //     <div className="btn">
    //         {/* <Link to="/documentos">Ver Resultados</Link> */}
    //     </div>,
    image: plano_mobilidade_banner,
    class: "plano-logo",
  },
  {
    title: "seminario-cidade",
    control: (
      <div className='btn'>
        <a href='/noticias'>SAIBA MAIS!</a>
      </div>
    ),
    image: seminario,
    class: "plano-logo",
    url: "https://pdmi.dacengenharia.com.br/noticias",
  },
];

function Banner() {
  return (
    <div className='Banner'>
      <div className='center'>
        <div className='banner-items'>
          <Carousel
            autoPlay={true}
            interval={4500}
            swipeable={true}
            emulateTouch={true}
            infiniteLoop={true}
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
          >
            {slides.map((slide) => (
              <div key={slide.title}>
                <div className={"image-wrapper " + slide.class}>
                  {slide.title === "plano-mobilidade" ? (
                    <img
                      className='logo'
                      src={slide.image}
                      alt='Plano diretor de Itajubá'
                    />
                  ) : (
                    <img src={slide.image} alt='Plano diretor de Itajubá' />
                  )}
                </div>
                <div>
                  {slide.control && (
                    <a href='/noticias' className='saiba-mais-button'>
                      SAIBA MAIS!
                    </a>
                  )}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Banner;
