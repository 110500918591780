import { Link } from 'react-router-dom';
import { FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';

import './Footer.css'

function Footer() {
    return (

        <div className='Footer'>
            <div className='center'>
                <div className='footer-items'>
                    <div className='footer--links'>
                        <Link to="/pesquisas">Pesquisas</Link>
                        <Link to="/documentos">Documentos</Link>
                        <Link to="/noticias">Notícias</Link>
                    </div>
                    <div className="footer--media">
                        {/* <a className='icon' href="https://www.sopaneiru.com">
                            <i className='sopa'></i>
                        </a> */}
                        <a target='_blank' rel="noreferrer" className='linkedin' href="https://www.linkedin.com/company/dac-engenharia/mycompany/">
                            <FaLinkedin className='fa' />
                        </a>
                        <a target='_blank' rel="noreferrer" className='instagram' href="https://www.instagram.com/dac_engenharia_ltda/">
                            <FaInstagram className='fa' />
                        </a>

                        <a className='email' href="mailto:planodiretor.itajuba@gmail.com">
                            <FaEnvelope className='fa' />
                        </a>
                    </div>
                    <div className='footer--copyright'>
                        <p>DAC Engenharia</p>
                        <p>© 2024</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;